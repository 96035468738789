import * as React from "react";
import PageLayout from "../../PageLayout";
import { Helmet } from "react-helmet";
import PrevNext from "../../components/PrevNext";
import SectionLayout from "../../SectionLayout";
import background from "../../images/previousprojects/1.jpg"
const Chorline = () => {
  return (
    <PageLayout
      noButton
      image={background}
      navSize={"h-96 xs:h-148 sm:h-116"}
      title={"Chlorine containers – disinfection program"}
      titleStyles={"xl:mt-24 lg:mt-24 md:mt-20 sm:mt-20 xs:mt-16 mt-8"}
    >
      <Helmet>
        <title>
          Chlorine containers - disinfection program | EPG Solutions
        </title>
        <meta
          name="description"
          content="Client: Power Water Completed: Ongoing Location: Belyuen, Daly River, Urapunga, Bulman, Jilkminggan. Scope: 10-Foot chlorine disinfection containers, Installation of the Distribution board, Telemetry panel, Wall mounted A/C, Chlorine Bund and associated equipment including a pre-wired pump termination junction box. Automated chlorine dossing of the community drinking water. Challenges: Remote location. Corrosiveness of chlorine required use."
        />
      </Helmet>
      <div className={"bg-epg-yellow h-5"} />
      <SectionLayout bgColor={"bg-epg-white"}>
        <div className={" py-5 "}>
          <div className={"px-2 mb-10 lg:leading-8 lg:text-lg"}>
            <p className={"mt-5 mr-2 font-exo2 font-bold text-epg-black"}>
              Client:
              <span
                className={"mt-5 ml-2 font-normal font-exo2 text-epg-black"}
              >
                Power Water
              </span>
            </p>
            <p className={"mt-5 mr-2 font-exo2 font-bold text-epg-black"}>
              Completed:
              <span
                className={"mt-5 ml-2 font-normal font-exo2 text-epg-black"}
              >
                Ongoing
              </span>
            </p>
            <p className={"mt-5 mr-2 font-exo2 font-bold text-epg-black"}>
              Location:
              <span
                className={"mt-5 ml-2 font-normal font-exo2 text-epg-black"}
              >
                Power Water
              </span>
            </p>
            <p className={"mt-5 mr-2 font-exo2 font-bold text-epg-black"}>
              Scope:
              <span
                className={"mt-5 ml-2 font-normal font-exo2 text-epg-black"}
              >
                10-Foot chlorine disinfection containers, Installation of the
                Distribution board, Telemetry panel, Wall mounted A/C, Chlorine
                Bund and associated equipment including a pre-wired pump
                termination junction box.
                <br />
                Automated chlorine dossing of the community drinking water.
              </span>
            </p>
            <p className={"mt-5 mr-2 font-exo2 font-bold text-epg-black"}>
              Challenges:
              <span
                className={"mt-5 ml-2 font-normal font-exo2 text-epg-black"}
              >
                Remote location. Corrosiveness of chlorine required use of
                plastic and stainless steel to prevent corrosion.
              </span>
            </p>
            <p className={"mt-5 mr-2 font-exo2 font-bold text-epg-black"}>
              Outcome:
              <span
                className={"mt-5 ml-2 font-normal font-exo2 text-epg-black"}
              >
                On time, on budget, happy customer.
              </span>
            </p>
          </div>
          <PrevNext
            prev={"laramba-borefield-power-station/"}
            next={"bore-panel-upgrades/"}
          />
        </div>
      </SectionLayout>
    </PageLayout>
  );
};

export default Chorline;
